import React from "react";
import { useTranslate } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ChapitreHelper from "../../helpers/ChapitreHelper";
import Typography from "@material-ui/core/Typography";
import { Create, Edit } from "react-admin";
import { Trans } from "react-i18next";
import useCurrentUser from "../../hooks/useCurrentUser";
import ChapitreReservationShow from "./ChapitreReservationShow";
import ChapitreReservationForm from "./ChapitreReservationForm";
import ChapitreReservationJaugeMessage from "./ChapitreReservationJaugeMessage";
export const chapitreReservationMobileViewBreakpoint = "sm";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        margin: theme.spacing(0),
        [theme.breakpoints.down(chapitreReservationMobileViewBreakpoint)]: {
            margin: theme.spacing(0, -4),
        },
    },
    content: {
        maxWidth: 1024,
        margin: "auto",
        padding: theme.spacing(3, 3),
    },
    estimate: {
        background: theme.palette.background.paper,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(1),
        boxSizing: "border-box",
        margin: theme.spacing(1, 0),
        ...theme.typography.button,
        textTransform: "none",
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    participantsTitle: {
        marginTop: theme.spacing(1),
    },
    dialogActions: {
        padding: theme.spacing(2, 2),
        textAlign: "center",
        justifyContent: "center",
    },
    participantWaitingList: {
        opacity: 0.8,
    },
    participant: {},
    cardActions: {
        paddingTop: 0,
        justifyContent: "flex-end",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
    },
    messageColor: {
        color: theme.palette.primary.main,
    },
    messageColorInfo: {
        color: theme.palette.primary.main,
        fontStyle: "italic",
    },
    icon: {
        verticalAlign: "middle",
        marginRight: theme.spacing(1), // Espacement entre l'icône et le texte
    },
}));

/**
 *
 * @param {object} props
 * @param {ChapitreHelper} props.chapitre
 */
const ChapitreReservation = ({
    chapitre,
    id,
    action,
    redirect,
    debug = false,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const user = useCurrentUser();
    const view = action ? action : id ? "show" : "create";
    let Component;
    const componentProps = {};
    switch (view) {
        case "create":
            Component = Create;
            componentProps.record = {
                chapitre: chapitre.id,
            };
            break;
        case "edit":
            Component = Edit;
            componentProps.undoable = false;
            componentProps.id = id;
            break;

        default:
            componentProps.id = id;
            break;
    }
    const limite_message_guichet_ouvert = 50;
    return (
        <div className={classes.root} {...props}>
            <div className={classes.content}>
                {Component ? (
                    <>
                        <h2>{translate("Votre réservation")}</h2>
                        {chapitre.id === 402 ||
                        chapitre.id === 403 ||
                        chapitre.id === 406 ? (
                            <h3 className={classes.messageColor}>
                                <Trans i18nKey="numerus_clausus.part1" />
                                <u>
                                    <Trans i18nKey="numerus_clausus.part2" />
                                </u>
                                <Trans i18nKey="numerus_clausus.part3" />
                            </h3>
                        ) : (
                            <></>
                        )}
                        <Typography
                            variant="caption"
                            className={classes.messageColorInfo}
                        >
                            <ChapitreReservationJaugeMessage
                                chapitre={chapitre}
                                limite_message_guichet_ouvert={
                                    limite_message_guichet_ouvert
                                }
                            />
                        </Typography>
                        <Component
                            resource={"reservationweb"}
                            basePath="/reservationweb"
                            component="div"
                            {...componentProps}
                        >
                            <ChapitreReservationForm
                                redirect={redirect}
                                classes={classes}
                                chapitre={chapitre}
                                user={user}
                                isEdit={view === "edit"}
                            />
                        </Component>
                    </>
                ) : (
                    <>
                        <ChapitreReservationShow
                            chapitre={chapitre}
                            {...componentProps}
                            classes={classes}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ChapitreReservation;
